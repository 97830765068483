import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

type DeleteModalProps = {
  title: string;
  name: string;
  buttonText?: string;
  close?: boolean; // 確定時にモーダルを閉じるかどうか
  handleDelete: () => void;
};

export const DeleteModal: React.VFC<DeleteModalProps> = ({
  title,
  name,
  buttonText,
  close,
  handleDelete,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button bg="red" color="white" onClick={onOpen}>
        {buttonText ?? "削除する"}
      </Button>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <p>{name}を削除します。この動作は戻せません。</p>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => {
                handleDelete();
                if (close) {
                  onClose();
                }
              }}
            >
              削除
            </Button>
            <Button onClick={onClose}>キャンセル</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
