import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { PenaltyResponse } from "__generated__/graphql";
import { RequireField } from "components/atoms/require-field";
import { PenaltySubmit } from "interfaces";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";

type PenaltyFormProps = {
  backToTable: () => void;
  submit: (input: PenaltySubmit) => Promise<void>;
  data?: PenaltyResponse;
};

export const PenaltyForm: React.VFC<PenaltyFormProps> = ({ backToTable, submit, data }) => {
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { name: data?.name, price: data?.price, kekkinPoint: data?.kekkinPoint },
  });

  const isEdit = useMemo((): boolean => {
    return !!data;
  }, [data]);

  const onSubmit = async (input: PenaltySubmit): Promise<void> => {
    // 入力欄を空にされた時のために
    if (Number.isNaN(input.kekkinPoint)) {
      input.kekkinPoint = 0;
    }
    await submit(input);
    toast({
      title: isEdit ? `${input.name}を編集しました。` : `${input.name}を新規作成しました。`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    backToTable();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={!!errors.name}>
        <FormLabel htmlFor="name">
          罰金名
          <RequireField />
        </FormLabel>
        <Input
          id="name"
          placeholder="罰金名を入力してください。"
          maxW="400px"
          {...register("name", {
            required: "罰金名が必要です。",
            maxLength: { value: 100, message: "100文字以下で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.price} mt={6}>
        <FormLabel htmlFor="price">
          金額
          <RequireField />
        </FormLabel>
        <Input
          id="price"
          type="number"
          placeholder="金額を入力してください。"
          maxW="400px"
          {...register("price", {
            required: "金額が必要です。",
            valueAsNumber: true,
            min: { value: 0, message: "0円以上で入力してください。" },
            max: { value: 50_000_000, message: "5000万円以下で入力してください。" },
            pattern: { value: /^\d+/, message: "数字で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.price?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.kekkinPoint} mt={6}>
        <FormLabel htmlFor="kekkinPoint">欠勤ポイント</FormLabel>
        <Input
          id="kekkinPoint"
          type="number"
          step="0.1"
          placeholder="全休「1」半休「0.5」のように入力"
          maxW="400px"
          {...register("kekkinPoint", {
            valueAsNumber: true,
            min: { value: 0, message: "0円以上で入力してください。" },
            max: { value: 50_000_000, message: "5000万円以下で入力してください。" },
            pattern: { value: /^\d+/, message: "数字で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.kekkinPoint?.message}</FormErrorMessage>
      </FormControl>
      <Button mt={8} colorScheme="blue" isLoading={isSubmitting} type="submit">
        {isEdit ? "編集する" : "登録する"}
      </Button>
    </form>
  );
};
