import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Button } from "@chakra-ui/react";
import { currencyFormat } from "@kg-pos/common";
import {
  PenaltyResponse,
  useCreatePenaltyMutation,
  useGetPenaltiesByStoreIdQuery,
} from "__generated__/graphql";
import { ErrorLayout } from "components/layouts/error";
import { MainTable } from "components/molecules/main-table";
import { PenaltyForm } from "components/organisms/penalty-form";
import { StoreContext } from "contexts/store.context";
import { PenaltySubmit } from "interfaces";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { routeConstants } from "routes";

const columns = [
  {
    Header: "罰金名",
    accessor: "name",
  },
  {
    Header: "金額",
    accessor: "price",
    Cell: (props: any) => <div>{currencyFormat(props.value)}</div>,
  },
  {
    Header: "欠勤ポイント",
    accessor: "kekkinPoint",
    Cell: (props: any) => <div>{props.value || ""}</div>,
  },
];

export const PenaltyLayout: React.VFC = () => {
  const { push } = useHistory();
  const [isForm, setForm] = useState<boolean>(false);
  const { storeId } = useContext(StoreContext);
  const { loading, error, data, refetch } = useGetPenaltiesByStoreIdQuery({
    variables: { storeId },
    fetchPolicy: "cache-and-network",
  });
  const [createPenalty] = useCreatePenaltyMutation();

  const backToTable = async (): Promise<void> => {
    setForm(false);
    await refetch();
  };

  const handleClick = (row: PenaltyResponse): void => {
    push(`${routeConstants.penalties}/${row.id}?storeId=${storeId}`);
  };

  const submit = async (input: PenaltySubmit): Promise<void> => {
    await createPenalty({
      variables: { storeId, ...input },
    });
  };

  if (loading) return null;
  if (error) return <ErrorLayout />;

  const penalties = data?.penaltiesByStoreId ?? [];

  return (
    <>
      {isForm ? (
        <Box>
          <ArrowBackIcon mb="6" onClick={backToTable} className="cursor-pointer" />
          <PenaltyForm backToTable={backToTable} submit={submit} />
        </Box>
      ) : (
        <>
          <Button colorScheme="blue" mb={6} onClick={() => setForm(true)}>
            罰金を登録する
          </Button>
          <MainTable data={penalties} columns={columns} handleClick={handleClick} />
        </>
      )}
    </>
  );
};
