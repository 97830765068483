import { ja } from "date-fns/locale";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "./month-year-picker.css";

registerLocale("ja", ja);

type MonthYearPickerProps = {
  startDate: Date;
  setStartDate: (date: Date) => void;
};

/** 月選択のDatePicker */
export const MonthYearPicker: React.FC<MonthYearPickerProps> = ({ startDate, setStartDate }) => (
  <ReactDatePicker
    wrapperClassName="monthYearPickerWrapper"
    className="datePickerStyle monthYearPickerStyle"
    showMonthYearPicker
    dateFormat="yyyy年MM月"
    selected={startDate}
    onChange={(date: Date) => setStartDate(date)}
    locale="ja"
  />
);
