import { currencyFormat } from "@kg-pos/common";
import { useDeletePenaltyMutation, useGetPenaltyQuery } from "__generated__/graphql";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { SingleRead } from "components/molecules/single-read";
import { PageTitle } from "components/organisms/page-title";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { routeConstants } from "routes";

export const PenaltyPage: React.VFC = () => {
  const id = useParams();
  useTitle("罰金");
  const { loading, error, data } = useGetPenaltyQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [deletePenalty] = useDeletePenaltyMutation();

  const penalty = data?.penalty;

  if (loading) return <LoadingLayout />;
  if (error || !penalty) return <ErrorLayout />;

  const handleDelete = async (): Promise<boolean> => {
    return !!(await deletePenalty({ variables: { id } })).data?.deletePenalty;
  };

  return (
    <>
      <PageTitle
        title="罰金詳細"
        breadCrumbItems={[
          { label: "罰金", path: `${routeConstants.stores}/${penalty.storeId}` },
          {
            label: "罰金詳細",
            path: `${routeConstants.penalties}/${id}`,
            active: true,
          },
        ]}
      />

      <SingleRead
        data={[
          { label: "名前", value: penalty.name },
          { label: "金額", value: currencyFormat(penalty.price) },
          { label: "欠勤ポイント", value: penalty.kekkinPoint || "" },
        ]}
        editPath={`${routeConstants.penalties}/edit/${id}`}
        deletePath={`${routeConstants.stores}/${penalty.storeId}`}
        handleDelete={handleDelete}
      />
    </>
  );
};
