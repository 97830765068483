import {
  Box,
  Button,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { currencyFormat, SalesTypeList } from "@kg-pos/common";
import {
  TitleRewardDataFragment,
  useDeleteHostMutation,
  useDeleteTitleRewardMutation,
  useGetHostQuery,
} from "__generated__/graphql";
import { getHostRecord } from "common/object/host";
import { MainCardContainer } from "components/containers/main-card";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { SingleRead } from "components/molecules/single-read";
import { DeleteModal } from "components/organisms/delete-modal";
import { PageTitle } from "components/organisms/page-title";
import { TitleRewardModal } from "components/organisms/title-reward-modal";
import dayjs from "dayjs";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import React, { useState } from "react";
import { routeConstants } from "routes";

export const HostPage: React.VFC = () => {
  const id = useParams();
  useTitle("ホスト詳細");
  const { loading, error, data, refetch } = useGetHostQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [deleteHost] = useDeleteHostMutation();
  const [deleteTitleReward] = useDeleteTitleRewardMutation();
  const titleRewardModal = useDisclosure();
  const [titleReward, setTitleReward] = useState<TitleRewardDataFragment | null>(null);
  const toast = useToast();

  const host = getHostRecord(data?.host);

  if (loading) return <LoadingLayout />;
  if (error || !host || !host.store) return <ErrorLayout />;

  const handleDeleteHost = async (): Promise<boolean> => {
    return !!(await deleteHost({ variables: { id } })).data?.deleteHost;
  };

  const handleDeleteTitleReward = async (titleRewardId: string): Promise<boolean> => {
    return !!(await deleteTitleReward({ variables: { id: titleRewardId } })).data
      ?.deleteTitleReward;
  };

  return (
    <>
      <PageTitle
        title="ホスト詳細"
        breadCrumbItems={[
          { label: "ホスト", path: `${routeConstants.stores}/${host.storeId}` },
          {
            label: "ホスト詳細",
            path: `${routeConstants.hosts}/${id}`,
            active: true,
          },
        ]}
      />

      <SingleRead
        data={[
          { label: "名前", value: host.name },
          { label: "読み", value: host.yomi },
          { label: "店舗", value: host.store.name },
          { label: "状態", value: host.status },
          { label: "ナンバー対象者", value: host.hostIsNumber },
          { label: "入店日", value: host.startDate },
          { label: "役職", value: host.title },
          { label: "表示優先度", value: host.sortNo },
        ]}
        editPath={`${routeConstants.hosts}/edit/${id}`}
        deletePath={`${routeConstants.stores}/${host.storeId}`}
        handleDelete={handleDeleteHost}
      />
      <Spacer h={5} />
      <MainCardContainer>
        <Box fontWeight="bold" mb={5}>
          役職手当
          <Button
            ml={3}
            colorScheme="blue"
            onClick={() => {
              setTitleReward(null);
              titleRewardModal.onOpen();
            }}
          >
            追加
          </Button>
        </Box>

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>内容</Th>
              <Th>適用開始月</Th>
              <Th>適用終了月</Th>
              <Th>操作</Th>
            </Tr>
          </Thead>
          <Tbody>
            {host.titleRewards?.map((titleReward) => (
              <Tr key={titleReward.id}>
                <Td>
                  {titleReward.amount > 0 && (
                    <Text>固定金額：{currencyFormat(titleReward.amount)}</Text>
                  )}
                  {titleReward.percentage > 0 && (
                    <Text>
                      パーセンテージ：{titleReward.percentage} %を{" "}
                      {SalesTypeList[titleReward.salesType]} に適用
                    </Text>
                  )}
                </Td>
                <Td>{dayjs(titleReward.startMonth).format("YYYY年MM月")}</Td>
                <Td>
                  {!!titleReward.endMonth && dayjs(titleReward.endMonth).format("YYYY年MM月")}
                </Td>
                <Td>
                  <Button
                    mr={3}
                    onClick={() => {
                      setTitleReward(titleReward);
                      titleRewardModal.onOpen();
                    }}
                  >
                    編集
                  </Button>
                  <DeleteModal
                    title="役職手当削除"
                    name="役職手当"
                    buttonText="削除"
                    close={true}
                    handleDelete={async () => {
                      if (await handleDeleteTitleReward(titleReward.id)) {
                        toast({
                          title: `削除しました。`,
                          status: "success",
                          duration: 9000,
                          isClosable: true,
                        });
                      } else {
                        toast({
                          title: `削除に失敗しました。`,
                          status: "error",
                          duration: 9000,
                          isClosable: true,
                        });
                      }
                      await refetch();
                    }}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </MainCardContainer>
      {titleRewardModal.isOpen && (
        <TitleRewardModal
          isOpen={titleRewardModal.isOpen}
          onClose={titleRewardModal.onClose}
          hostId={id}
          data={titleReward}
          refetch={refetch}
        />
      )}
    </>
  );
};
